var generic = generic || {};
var prodcat = prodcat || {};

(function ($) {
  Drupal.behaviors.nddCountdown = {

    getTimeRemaining: function (seconds_left) {
      var t = seconds_left;
      var minutes = Math.floor((t / 60) % 60);
      var hours = Math.floor((t / (60 * 60)) % 24);

      return {
        'total': t,
        'hours': hours,
        'minutes': minutes
      };
    },

    initializeClock: function (seconds_left, $clock) {
      var hoursSpan = $clock.find('.hours');
      var minutesSpan = $clock.find('.minutes');

      function updateClock() {
        var t = Drupal.behaviors.nddCountdown.getTimeRemaining(seconds_left);

        if (t.hours <= 0) {
          hoursSpan.hide();
        }
        hoursSpan.html(t.hours + Drupal.behaviors.nddCountdown.pickyScheck(t.hours, ' hr'));
        minutesSpan.html(('0' + t.minutes).slice(-2) + Drupal.behaviors.nddCountdown.pickyScheck(t.minutes, ' min')); // forcing a 2 digit minute
        if (t.total <= 0) {
          $clock.hide();
          clearInterval(timeinterval);
        }
      }
      $clock.show();
      updateClock();
      var timeinterval = setInterval(updateClock, 10000);
    },

    // Check whether we should be showing NDD Callout
    isMethodValid: function (sku_base_id, $clock) {
      var skuData = prodcat.data.getSku(sku_base_id);

      if (skuData && skuData.isOrderable) {
        Drupal.behaviors.nddCountdown.secondsLeft($clock);
      }
    },

    pickyScheck: function (num, str) {
      if (num > 1) {
        return str + 's';
      }

      return str;
    },

    secondsLeft: function ($clock) {
      var time_limit_upper = Number.isInteger($clock.data('time-upper')) ? $clock.data('time-upper') : 18000; // Default 5 hours unless set in CMS global product translations
      var time_limit_lower = Number.isInteger($clock.data('time-lower')) ? $clock.data('time-lower') : 300; // Default 5 minutes unless set in CMS global product translations

      if (generic.jsonrpc) {
        generic.jsonrpc.fetch({
          method: 'nextdaytime.get',
          onSuccess: function (t) {
            var timeData = t.getValue();

            if (timeData) {
              if (timeData[0].label.toLowerCase() === 'time left') {
                var seconds_left = timeData[0].value;

                if (seconds_left > time_limit_lower && seconds_left < time_limit_upper) {
                  Drupal.behaviors.nddCountdown.initializeClock(seconds_left, $clock);
                }
              }
            }
          }
        });
      }
    },

    attach: function (context) {
      var $clock = $('.js-product-ndd-countdown', context);
      var $product = $('.js-product', context);
      var sku_base_id = $product.data('sku-base-id');

      if ($clock.length > 0) {
        this.isMethodValid(sku_base_id, $clock);
      }
    }

  };
})(jQuery);
